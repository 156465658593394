.AIF {
  font-size: 15px !important;
  input, textarea {
    font-size: 15px !important;
  }
  div.bp4-html-select {
    max-width: 90%;
  }
  div.bp4-html-select > select {
    height: 29px !important;
  }
  div.bp4-html-select .bp4-icon {
    top: 7px !important;
  }
  textarea {
    padding: 5px 6px;
    border-radius: 6px;
    width: 100%;
  }
  div.inputtype-number, div.inputtype-text, div.inputtype-email, div.inputtype-phone {

    > input {
      height: 29px;
      padding: 5px 6px;
      font-size: 15px !important;
      border-radius: 6px;
    }
  }
  div.birthday-selector {
    grid-gap: 8px !important;
    max-width: 340px;
  }
  div.birthday-selector > div.month > div > div.bp4-html-select {
    max-width: 100% !important;
  }
}