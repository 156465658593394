@font-face {
    font-family: "Inter";
    src: url("../library/fonts/Inter-SemiBold.woff") format('woff'), url("../library/fonts/Inter-SemiBold.woff2") format('woff2');
    font-weight: 600;
}
@font-face {
    font-family: "Inter";
    src: url("../library/fonts/Inter-Regular.woff") format('woff'), url("../library/fonts/Inter-Regular.woff2") format('woff2');
    font-weight: normal;
}
@font-face {
    font-family: "Inter";
    src: url("../library/fonts/Inter-Medium.woff") format('woff'), url("../library/fonts/Inter-Medium.woff2") format('woff2');
    font-weight: 500;
}

body {
    line-height: 1.28581;
}

.simple-loader {
    border: 0.2rem solid #f3f3f3; /* Light grey */
    border-top: 0.2rem solid #3498db; /* Blue */
    border-radius: 50%;
    animation: spin 0.65s linear infinite;
}

.simple-loader.md {
    border: 0.5rem solid #f3f3f3; /* Light grey */
    border-top: 0.5rem solid #3498db; /* Blue */
}

.simple-loader.lg {
    border: 1rem solid #f3f3f3; /* Light grey */
    border-top: 1rem solid #3498db; /* Blue */
}

.simple-progress-bar-animate > span:after, .simple-progress-bar-animate > span > span {
    content: "";
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    background-image: linear-gradient(
            -45deg,
            rgba(255, 255, 255, .2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, .2) 50%,
            rgba(255, 255, 255, .2) 75%,
            transparent 75%,
            transparent
    );
    z-index: 1;
    background-size: 30px 30px;
    animation: move 1.2s linear infinite;
    border-radius: 32px;
    overflow: hidden;
}

@keyframes moveProgress {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 30px 30px;
    }
}

.simple-progress-bar > span {
    display: block;
    transition: 0.1s width linear;
    height: 100%;
    border-radius: 32px;
    position: relative;
    overflow: hidden;
}

.bp4-portal {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.bp4-overlay {
    bottom: 0;
    left: 0;
    position: static;
    right: 0;
    top: 0;
    z-index: 20;
}

.bp4-overlay.bp4-overlay-open {
    display: flex;
    justify-content: center;
}

.bp4-overlay.bp4-overlay-open > div:nth-child(3) {
    position: fixed;
    z-index: 20;
    outline: none;
}

.bp4-overlay-backdrop {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    opacity: 1;
    background-color: rgba(16,22,26,.7);
    overflow: auto;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 20;
}

.bp4-overlay-content {
    position: fixed;
    z-index: 20;
    outline: none;
}

.mobile-page-height {
    height: calc(100vh - 3rem);
}

.mobile-page-height-header {
    height: calc(100vh - 6rem);
    overflow-y: auto;
}

.mobile-content-padding {
    padding: 1rem 1rem 8rem 1rem;
}

.mobile-content-side-padding {
    padding: 0rem 1rem 8rem 1rem;
}

.mobile-policy-gate.z-9999 {
z-index: 99999 !important;
}

.mobile-policy-gate > div {
padding: 0 !important;
}

.mobile-bottom-padding {
    padding-bottom: 8rem;
}

.mobile-photo-selector .bp4-overlay-content {
    position: fixed !important;
    inset: auto !important;
    left: 0.5rem !important;
    right: 0.5rem !important;
    bottom: 3rem !important;
}

.mobile-modal .bp4-overlay-content {
    position: fixed !important;
    inset: auto !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    max-width: 100vw;
    overflow-x: hidden;
}

.simple-progress-bar-animate > span::after, .simple-progress-bar-animate > span > span {
    animation: moveProgress 1.2s linear infinite;
}

.simple-progress-bar-animate > span::after {
    display: none;
}

@media not all and (min-resolution:.001dpcm) { @media {
    .safari_only {

    }
    .icon-24px > svg {
        height: 18px !important;
    }
    svg {
        height: inherit;
    }
}}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.sidebar-content-height {
    height: calc(100vh - 6rem);
}

.font-mono {
    font-family: "Lucida Console", Monaco, monospace;
}

.bg-moderator {
   background-color: #5A76AB;
}

.bg-role {
    background-color: #04BAA3;
}

.bg-leader {
    background-color: #04BAA3;
}

.bg-unaty {
    background-color: #F15F31;
}

.bg-admin {
    background-color: #9336E3;
}

.bp4-overlay-content {
    outline: none !important;
}
.bp4-popover2-target {
    outline: none !important;
}

div[tabindex="-1"] {
    outline: none !important;
}

.bp4-tooltip.bp4-minimal {
    border-radius: 6px !important;
    border: none !important;
}
.bp4-tooltip.bp4-minimal > div {
    font-size: 14px !important;
    font-weight: 500 !important;
    background: #19191c !important;
    border-radius: 6px !important;
    padding: 8px 12px !important;
}
.popup-box-shadow .bp4-popover-content {
    border: none !important;
    border-radius: 6px !important;
}
.emoji-mart-anchors .emoji-mart-anchor-icon {
    display: flex;
    justify-content: center;
}

.bp4-popover.bp4-minimal.minimal-popover {
    box-shadow: none !important;
}

.bp4-popover.bp4-minimal.margin-top-8 {
    margin-top: 8px !important;
}

.edit-body-cell-popover.bp4-popover.bp4-minimal.minimal-popover {
    min-width: 300px !important;
    margin-top: -34px !important;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 3px 14px 0 rgba(0, 0, 0, .1), 0 2px 4px 0 rgba(0, 0, 0, .06) !important;
}

.edit-body-cell-popover.bp4-popover.bp4-minimal.minimal-popover {
    min-width: 300px !important;
    margin-top: -34px !important;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 3px 14px 0 rgba(0, 0, 0, .1), 0 2px 4px 0 rgba(0, 0, 0, .06) !important;
}


.bp4-overlay-content {
    outline: none;
}

.table-footer-popup.bp4-popover.bp4-minimal.minimal-popover {
    min-width: 260px !important;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 3px 14px 0 rgba(0, 0, 0, .1), 0 2px 4px 0 rgba(0, 0, 0, .06) !important;
}


.bp4-toast {
    background-color: inherit !important;
}


.margin-top-8, .bp4-popover.bp4-minimal.margin-top-8 {
    margin-top: 8px !important;
}

.margin-top-16, .bp4-popover.bp4-minimal.minimal-popover.margin-top-16px {
    margin-top: 16px !important;
}

.margin-left-6, .bp4-popover.bp4-minimal.margin-left-6 {
    margin-left: 6px !important;
}

.minimal-popover.bp4-popover.bp4-minimal.margin-top-2 {
    margin-top: 2px !important;
}

.no-margin-top.minimal-popover.bp4-popover.bp4-minimal {
    margin-top: 0 !important;
}

.popover-standard.bp4-popover.bp4-minimal {
    min-width: 320px;
    margin-top: 6px !important;
}

.minimal-popover.bp4-popover.bp4-minimal {
    box-shadow: none !important;
    margin-top: 6px !important;
}

.top-margin-4px.minimal-popover.bp4-popover.bp4-minimal {
    margin-top: 4px !important;
}

.comm-switcher-popover.minimal-popover.bp4-popover.bp4-minimal {
    margin-top: 4px !important;
}

.comm-switcher-popover.minimal-popover.bp4-popover.bp4-minimal > div {
    background: none !important;
}

.comm-switcher-popover.minimal-popover.bp4-popover.bp4-minimal > div > div {
    width: auto;
    background-color: #fff;
    text-align: center;
    max-width: 250px;
}

.margin-top-4px.bp4-popover.bp4-minimal {
    margin-top: 4px !important;
}

.margin-bottom-4px.bp4-popover.bp4-minimal {
    margin-bottom: 4px !important;
}

.margin-bottom-8px.bp4-popover.bp4-minimal {
    margin-bottom: 8px !important;
}


.margin-left-5px.bp4-popover.bp4-minimal {
    margin-left: 5px !important;
}

.advanced-modal-portal .bp4-overlay-content {
    outline: none !important;
}

.file-preview-portal .bp4-overlay-content {
    outline: none !important;
}
.sk-circle {
    margin: 0 auto;
    width: 40px;
    height: 40px;
    position: relative;
}
.sk-circle.sk-mini {
    width: 24px;
    height: 24px;
}
.sk-circle.sk-tiny {
    width: 18px;
    height: 18px;
}
.sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #c8c8cb;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle.sk-mini .sk-child:before {
    width: 12%;
    height: 12%;
}
.sk-circle.sk-tiny .sk-child:before {
    width: 10%;
    height: 10%;
}
.sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg); }
.sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg); }
.sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }
.sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg); }
.sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg); }
.sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
.sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg); }
.sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg); }
.sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg); }
.sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg); }
.sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg); }
.sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s; }
.sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s; }
.sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s; }
.sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s; }
.sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s; }
.sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s; }
.sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s; }
.sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s; }
.sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s; }
.sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s; }
.sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
}

@keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
}

.bp4-popover-target > * {
    outline: none !important;
}

body {
    font-family: "Inter", -apple-system, Helvetica Neue, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

@media screen and (min-width: 768px) {
    html, body {
        overflow: hidden;
    }
}

@media screen and (max-width: 768px) {

}

#members-map div[title^="Pin"] {
    overflow: hidden !important;
    border-radius: .375rem;
}

#members-map div[title^="Pin"] > img {
    border: 2px solid #52535a !important;

    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.05);
    border-radius: .375rem;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
}

#members-map div[title^="Pin - "] > img:hover {
    opacity:0.8;
}

.inputtype-handle > span {
    position: absolute;
    left: 10px;
    top: 8px;
}

.inputtype-handle > input {
    padding-left: 30px;
}

.z-99, .z-99.bp4-portal {
    z-index: 99 !important;
}

.z-999, .z-999.bp4-portal {
    z-index: 999 !important;
}

.z-9999, .z-9999.bp4-portal {
    z-index: 9999 !important;
}

.bp4-portal:not(.advanced-modal-portal):not(.z-999):not(.z-9999):not(.z-9999) {
    z-index: 99 !important;
}

.bp4-portal.advanced-modal-portal {
    z-index: 9999 !important;
}

.z-99999, .z-99999.bp4-portal {
    z-index: 99999 !important;
}

.z-999999, .z-999999.bp4-portal {
    z-index: 999999 !important;
}

.h-svg-45 > svg {
    height: 1.1rem !important;
    width: 1.1rem !important;
}

.h-svg-18 > svg {
    height: 18px !important;
    width: 18px !important;
}

.svg-child-height-min > svg {
    height: 1rem;
}

.post-content-container {
    max-width: 680px;
}

.feed-item-read {
    background: #F1F6FD;
}

.feed-item-border {
    border-color: rgb(229, 229, 229);
}

.feed-item-bg:hover {
    background: #F7F7F7;
}

.text-xssm {
    font-size: 0.85rem;
}

.text-smbase {
    font-size: 0.95rem;
}

.border-bottom-2-hover:hover {
    border-bottom-width: 2px !important;
}

.special-standard-bottom-compact-item {

}

.emoji-location {
    margin-top: -1px;
font-size: 1.25rem;
}

@media not all and (min-resolution:.001dpcm) { @media {
    /*
      Define here the CSS styles applied only to Safari browsers
      (any version and any device)
     */
    .emoji-location {
        margin-top: -1px;
font-size: 0.9rem !important;
    }
}}

.mentions-popup-styles {
    border-radius: 6px;
    padding: 4px 0;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 3px 14px 0 rgba(0, 0, 0, .1), 0 2px 4px 0 rgba(0, 0, 0, .06) !important;
}

.tb-select-value {
    padding: 1px 4px;
    border-radius: 6px;
    background-color: rgba(244, 244, 245, 1);
}

.tb-select-warning {
    padding: 1px 4px;
    border-radius: 6px;
}

.tb-link-value {
    background-color: #F2F4FB;
    color: #336ECE;
    padding: 1px 4px;
    border-radius: 6px;
}

.tb-entity-value {
    background-color: #F2F4FB;
    padding: 1px 4px;
    border-radius: 6px;
}

.tb-handle-link {
    color: #336ECE;
}

.tb-handle-link:hover {
    color: #122f61;
}

.table-resizer {
    display: inline-block;
    background: rgba(244, 244, 245, 1);
    width: 1px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action:none;
}

.table-resizer:hover {
    background: #72a0d9;
    width: 2px;
}

.table-resizer.isResizing {
    background: #375580;
    width: 2px;
    cursor: e-resize !important;
}

.sticky-important {
    position: sticky !important;
}

.directory-checkbox {
    accent-color: #4882c9;
}

.bg-inherit {
    background-color: inherit;
}