@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .custom_radio > input[type="radio"] {
        display: none;
    }

    .pl-phone-number {
        padding-left: 5.6rem;
    }

    .hidden-imp {
        display: none !important;
    }

    .pl-phone-number-select {
        padding-left: 3.6rem;
    }

    .standard-entity-profile-grid {
        display: grid;
        grid-template-columns: 600px 1fr;
        grid-gap: 2.5rem;
    }

    .profile-about-grid-split {
        display: grid;
        grid-template-columns: 1fr 180px;
        grid-gap: 1rem;
    }

    .background-image-cover-responsive {
        background-size: 101%;
    }

    @media all and (max-width: 1024px) {
        .standard-entity-profile-grid {
            grid-template-columns: 1fr;
            display: flex;
            justify-content: center;
        }

        .standard-entity-profile-grid > div:nth-child(2) {
            display: none;
        }

        .standard-entity-profile-grid > div:nth-child(1) {
            width: 100%;
        }
    }

    @media all and (max-width: 800px) {
        .hide-under-800 {
            display: none;
        }

        .background-image-cover-responsive {
            background-size: cover;
        }

        .profile-about-grid-split {
            grid-template-columns: 1fr;
        }

        .profile-about-grid-split > div:nth-child(2) {
            display: none;
        }
    }

    .community-cover-photo-bkg {
        background-color: #ffefe0;
        background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23fba368' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-size: auto !important;
    }

    .emoji-font {
        font-family: "Segoe UI Symbol" !important;
    }

    .app-width {
        width: 100%;
        max-width: 1443px;
    }

    .max-h-100vh {
        max-height: 100vh !important;
    }

    .max-h-70vh {
        max-height: 70vh !important;
        overflow: auto !important;
    }

    .important-shadow-md {
        @apply shadow-md !important;
    }

    .max-h-85vh {
        max-height: 85vh !important;
        overflow: auto !important;
    }

    .prose h1 {
        font-weight: 700 !important;
        font-size: 2em !important;
    }

    .prose img {
        margin-bottom: 0.15rem !important;
        margin-top: 0.15rem !important;
    }

    .prose figure {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .custom_radio > input[type="radio"] + *::before {
        content: "";
        display: inline-table;
        vertical-align: bottom;
        width: 1rem;
        height: 1rem;
        margin-right: 0.3rem;
        border-radius: 50%;
        border-style: solid;
        border-width: 0.1rem;
        border-color: gray;
    }

    .interest-tag-spacing > * {
        margin-right: 0.6rem !important;
        margin-bottom: 0.6rem !important;
    }

    .border-transparent-imp {
        border-color: transparent !important;
    }

    @media all and (max-height: 768px) {
        #scroll-parent {
            max-height: 100% !important;
        }

        .scrollable-content {

        }
    }
    #scroll-parent {

        height: 100%;
    }

    .scrollable-content {

    }

    .fixed-important {
        position: fixed !important;
    }

    .real-outline-none {
        outline: none !important;
    }

    .toasts.fixed-important {
        bottom: 0;
        right: 0;
    }

    .toasts.fixed-important > div {
        display: flex;
        flex-flow: column;
        align-items: end;
        padding-right: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .select-badge-padding {

        padding: 0.2rem 0.6rem !important;
    }

    .square-34px {
        height: 34px;
        width: 34px;
    }

    .height-34px {
        height: 34px;
    }

    .width-34px {
        width: 34px;
    }

    .custom_radio > input[type="radio"]:checked + *::before {
        background: radial-gradient(currentColor 0%, currentColor 40%, transparent 50%, transparent);
        border-color: currentColor;
    }

    .custom_radio > input[type="radio"]:checked + * {
        color: currentColor;
    }

    .rounded-42 {
        border-radius: 42%;
    }

    .grow-wrap {
        /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
        display: grid;
    }

    .grow-wrap::after {
        /* Note the weird space! Needed to preventy jumpy behavior */
        content: attr(data-replicated-value) " ";

        /* This is how textarea text behaves */
        white-space: pre-wrap;

        /* Hidden from view, clicks, and screen readers */
        visibility: hidden;
    }

    .grow-wrap > textarea {
        /* You could leave this, but after a user resizes, then it ruins the auto sizing */
        resize: none;

        /* Firefox shows scrollbar on growth, you can hide like this. */
        overflow: hidden;
    }

    .grow-wrap > textarea,
    .grow-wrap::after {
        /* Identical styling required!! */
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font: inherit;

        /* Place on top of each other */
        grid-area: 1 / 1 / 2 / 2;
    }

    .markdown-styles h1 {
        @apply prose-2xl;
        font-weight: 700;
        @apply text-gray-900;
    }

    .markdown-styles h2 {
        @apply prose-xl;
        font-weight: 700;
        @apply text-gray-800;
    }

    .markdown-styles h3 {
        @apply prose-lg;
        font-weight: 600;
        @apply text-gray-700;
    }

    .markdown-styles p, .markdown-styles ul, .markdown-styles ol {
        @apply leading-relaxed;
        @apply text-gray-700;
    }

    .markdown-styles ul {
        @apply list-disc list-inside;
    }

    .markdown-styles ol {
        @apply list-decimal list-inside;
    }

    .markdown-styles blockquote {
        padding: 10px 20px;
        @apply text-lg;
        border-left: 5px solid #eee;
    }

    .markdown-styles blockquote > p:last-child {
        margin-bottom: 0 !important;
    }

    .markdown-styles hr {
        @apply my-4;
    }

    .markdown-styles pre {
        display: block;
        @apply p-3;
        margin: 0 0 10px;
        font-size: 13px;
        line-height: 1.42857143;
        color: #333;
        word-break: break-all;
        word-wrap: break-word;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .markdown-styles p > code {
        padding: 2px 4px;
        font-size: 90%;
        color: #c7254e;
        background-color: #f9f2f4;
        border-radius: 4px;
    }

    .small-shadow {
        @apply shadow-sm;
    }

    .wobble-items > *:nth-child(2n) {
        animation-name: keyframes1;
        animation-iteration-count: infinite;
        animation-delay: -.75s;
        animation-duration: .25s;
        transform-origin: center;
    }

    .wobble-items > *:nth-child(2n-1) {
        animation-name: keyframes2;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-delay: -.75s;
        animation-duration: .25s;
        transform-origin: center;
    }

    @keyframes keyframes1 {
        0% {
            transform: rotate(-0.5deg);
            animation-timing-function: ease-in;
        }

        50% {
            transform: rotate(0.75deg);
            animation-timing-function: ease-out;
        }
    }

    @keyframes keyframes2 {
        0% {
            transform: rotate(1deg);
            animation-timing-function: ease-in;
        }

        50% {
            transform: rotate(-1.5deg);
            animation-timing-function: ease-out;
        }
    }

    div[tabindex="-1"]:focus {
        outline: 0;
    }

    .input-is-valid > div > input {
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, #339672 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    }

    .input-is-erroneous > div > input {
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(238, 73, 82, 0.6) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    }

    .input-warning > div > input {
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(255, 197, 88, 0.6) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    }

    .unbutton[disabled] {
        cursor: not-allowed;
        background-color: #b1b5b1;
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, #b1b5b1 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    }

    .search-popover {
        width: 440px;
        border-radius: 6px;
    }

    .admin-dots-button.un-button.medium.icon-only > .icon svg {
        height: 18px !important;
    }

    .un-button.small.icon-only {
        display: inline-flex;
        padding: 0 !important;
        width: 28px !important;
        min-width: 28px !important;
        align-items: center;
    }

    .un-button.small.icon-only > .icon {
        display: flex;
    }

    .un-button.small.icon-only > .icon svg {
        height: 18px !important;
    }

    .directory-grid {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .directory-grid.filtering {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    @media (max-width: 1440px) {
        .directory-grid {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }

        .directory-grid.filtering {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
    }
    @media (max-width: 960px) {
        .directory-grid {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        .directory-grid.filtering {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    @media (max-width: 768px) {
        .directory-grid {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .directory-grid.filtering {
            grid-template-columns: 1fr 1fr;
        }
    }
    .preloader-frame {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 2;
        left: 0;
        right: 0;
        background-color: #fff;
    }

    .preloader-container {
        max-width: 610px;
        margin: 0 auto;
    }

    .preloader-inner {
        margin: 0 24px;
        padding-top: 24vh;
    }

    .preloader-frame div {
        font-family: "Inter", -apple-system, Helvetica Neue, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    }

    .preloader-title {
        color: #112d42;
        font-weight: 600;
        font-size: 20px;
        line-height: 1.35;
        margin-top: 40px;
    }

    .preloader-author {
        color: #8193a8;
        margin-top: 6px;
    }

    @keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.7;
        }
        100% {
            opacity: 1;
        }
    }
    @-o-keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.7;
        }
        100% {
            opacity: 1;
        }
    }
    @-moz-keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.7;
        }
        100% {
            opacity: 1;
        }
    }
    @-webkit-keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.7;
        }
        100% {
            opacity: 1;
        }
    }
    .animate-flicker {
        -webkit-animation: flickerAnimation 2s infinite;
        -moz-animation: flickerAnimation 2s infinite;
        -o-animation: flickerAnimation 2s infinite;
        animation: flickerAnimation 2s infinite;
    }

    .unaty-spinner-box {
        text-align: center;
    }

    .unaty-spinner {
        animation: rotate 2s linear infinite;
        z-index: 2;
        height: 50px;
        width: 50px;
        margin: 0 auto;
    }

    .unaty-spinner .path {
        stroke: #f96721;
        strokeLinecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
    .loading-ellipsis:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(4, end) 1500ms infinite;
        animation: ellipsis steps(4, end) 1500ms infinite;
        content: "\2026";
        /* ascii code for the ellipsis character */
        width: 0px;
    }

    @keyframes ellipsis {
        to {
            width: 20px;
        }
    }
    @-webkit-keyframes ellipsis {
        to {
            width: 20px;
        }
    }
    .disabled-field input {
        cursor: no-drop;
        opacity: 0.5;
        background: #e7e7e7;
    }

    .rd-container {
        max-width: 1600px;
    }

    .inverted-popover > div > div {
        background: #1a2034;
        color: #fff;
        font-weight: 600;
    }

    .slide-in-right {
        -webkit-animation: slide-in-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    #app-frame {
        transition: 0.25s margin, filter ease-in-out;
        height: 100vh;
        box-shadow: 0px 0 10px rgba(0, 0, 0, 0.2);
        width: 100vw;
        position: relative;
        z-index: 2;
    }

    .show-switcher {
        margin-top: 106px;
        filter: blur(3px);
    }

    .unaty-frame.top-padded {
        padding-top: 3.25rem;
    }

    .unaty-frame {
        display: grid;
        grid-template-columns: auto 234px 1006px auto;
    }

    .app-topbar {

    }

    .adaptive-modal-height {
        height: 58vh;
    }

    .app-page-height {
        height: calc(100vh - 3.25rem);
    }

    .display-grid-1000 {
        display: block;
    }

    .grid-1000 {
        grid-template-columns: minmax(610px, 1fr) minmax(200px, 440px);
    }

    .sidebar-main-item {
        display: grid;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        padding-left: 0.5rem;
    }

    .sidebar-entity-item {
        display: grid;
    }

    .app-topbar-spacer {

    }

    .app-max-width {
        max-width: 1240px;
    }

    .app-body-grid {
        grid-template-columns: auto 240px minmax(600px, 1000px) auto;
    }

    .app-content-grid {
        grid-template-columns: 600px 1fr;
    }
    .app-sidebar-content {
        width: 240px;
    }
    @media all and (max-width: 1250px) {

        .app-max-width {
            max-width: 1080px;
        }

        .app-sidebar-content {
            width: 60px;
        }

        .app-body-grid {
            grid-template-columns: auto 60px minmax(600px, 1020px) auto;
        }

        .app-content-grid {
            grid-template-columns: 600px 1fr;
        }

        .unaty-frame {
            grid-template-columns: auto 60px minmax(600px, 990px) auto !important;
        }

        .unaty-topbar {
            padding-right: 0.5rem;
        }

        .unaty-right-sidebar-spacing {
            padding-right: 0.5rem;
        }

        .app-topbar-spacer {
            display: grid !important;
            grid-template-columns: 60px 1fr;
        }

        .sidebar-entity-item {
            display: flex !important;
            justify-content: center !important;
            padding: 0.25rem !important;
        }

        .sidebar-main-item {
            display: block !important;
            padding: 0.25rem !important;
        }

        .hidden-1250 {
            display: none !important;
        }

        .show-1250 {
            display: block !important;
        }

    }
    @media all and (min-width: 1001px) {



        .display-grid-1000 {
            display: grid;
        }

        .flex-1001 {
            display: flex !important;
        }

        .block-1001 {
            display: block !important;
        }
    }
    @media all and (max-width: 1020px) {

        .app-max-width {
            max-width: 680px;
        }

        .app-body-grid {
            grid-template-columns: auto 60px minmax(320px, 620px) auto;
        }

        .app-content-grid {
            grid-template-columns: minmax(320px,600px);
        }
        .app-content-grid > div:nth-child(2) {
            display: none;
        }

    }
    @media all and (min-width: 951px) {
        .hide-1001 {
            display: none !important;
        }
    }
    @media all and (max-width: 950px) {
        .unaty-frame {
            grid-template-columns: auto 60px minmax(450px, 1fr) auto !important;
        }

        .app-topbar-spacer {

        }

        .hidden-950 {
            display: none !important;
        }


        .grid-1000 {
            grid-template-columns: 1fr;
        }

        .hide-1000 {
            display: none !important;
        }

        .sidebar-entity {
            grid-template-columns: 20px 0px 0px !important;
            padding: 5px !important;
            width: 30px !important;
            grid-gap: 0 !important;
            margin: 4px auto 0 auto !important;
        }

        .sidebar-footer {
            grid-template-columns: 42px 0 0 !important;
            grid-gap: 0 !important;
            padding: 6px 9px 16px 9px !important;
        }

        .sidebar-logo {
            grid-gap: 0 !important;
            grid-template-columns: 1fr 0;
            width: 28px;
        }

        .sidebar-main-item {
            grid-template-columns: 24px 0;
            grid-gap: 0;
            width: 40px;
            margin: 0 auto;
        }
    }
    @media all and (max-width: 768px) {

        .app-search-main {
display: none;
        }
        .app-body-grid {
            grid-template-columns: auto 60px minmax(320px, 620px) auto;
        }
        .adaptive-modal-height {
            height: auto;
        }

        .advanced-modal-wrapper {
            max-width: 100vw !important;
            border-radius: 0 !important;
            min-height: 100vh;
        }
    }

    .app-frame-grid {
        grid-template-rows: 3.25rem 1fr;
    }
    .max-h-body {
        max-height: calc(100vh - 3.25rem);
    }
    .detail-bar {
        min-width: 100px;
        width: 100%;
        max-width: 600px;
    }
    @media all and (max-width: 550px) {
        .app-frame-grid {
            grid-template-rows: 1fr;
        }
        .max-h-body {
            max-height: 100vh;
        }
        .app-max-width {
            max-width: 680px;
        }

        .app-body-grid {
            overflow-x: hidden;
            grid-template-columns: auto minmax(320px, 1fr) auto;
        }

        .no-mobile-borders {
            border: none !important;
        }

        .app-sidebar, .app-topbar {
            display: none;
        }

        .app-content-grid {
            grid-template-columns: 1fr;
        }
        .app-content-grid > div:nth-child(2) {
            display: none;
        }

        .unaty-topbar {
            display: none !important;
        }

        .unaty-right-sidebar-spacing {
            padding-right: 0 !important;
        }

        .mobile-cover-photo {
            border-radius: 0 !important;
        }

        .unaty-frame.top-padded {
            padding-top: 0 !important;
        }

        .unaty-frame {
            padding-top: 0;
            grid-template-columns: 0 1fr 0 !important;
        }

        .hidden-550 {
            display: none !important;
        }
    }
    @media all and (max-width: 450px) {
        .app-topbar {
            display: none;
        }

        .hidden-450 {
            display: none !important;
        }

        .app-page-height {
            height: 100vh;
        }

        .unaty-sidebar {
            display: none !important;
        }
    }
    .ci {
        height: 20px;
    }

    .ci .ci-fill {
        transition: 0.05s fill ease;
        fill: #ffffff;
    }

    .ci .ci-strokefill {
        transition: 0.05s fill ease;
        fill: #ffffff;
    }

    .ci .ci-stroke {
        transition: 0.05s stroke ease;
        stroke: #1d1d1d;
    }

    .dark-mode .ci .ci-fill {
        fill: #27272c;
    }

    .dark-mode .ci .ci-strokefill {
        fill: #bebfc6;
    }

    .dark-mode .ci .ci-stroke {
        stroke: #bebfc6;
    }

    .ci:hover .ci-fill {
        fill: #ffe9da;
    }

    .ci:hover .ci-strokefill {
        fill: #ff7900;
    }

    .ci:hover .ci-stroke {
        stroke: #ff7900;
    }


    .popover-target-span {
        width: 100%;
    }

    .scrollable-content {
        overflow-y: auto;
    }

    .resize-parent > div:nth-child(1):hover {
        background-color: #bfd6eb;
    }

    .user-select-none {
        user-select: none;
    }

    .capitalize-text {
        text-transform: capitalize;
    }


    .toasts {
        z-index: 9999;
    }


    @media all and (max-width: 1000px) {
        .sidebar-menu-grid {
            grid-template-rows: 1fr 1fr !important;
            grid-template-columns: 1fr !important;
            grid-gap: 12px !important;
        }

        .sidebar-footer {
            padding: 0 !important;
            grid-template-columns: 1fr !important;
            margin-left: -4px !important;
        }
    }
    input:focus, textarea:focus, select:focus {
        outline: none !important;
        transition: border 80ms ease-out 0s, box-shadow 80ms ease-out 0s;
    }

    .new-focus-standard {
        box-shadow: 0 1px 2px 0 #0000000d;
        border-color: #d4d4d8;
        border-width: 1px;
        border-style: solid;

        border-radius: 0.375rem;
    }

    .new-focus-standard:hover {
        border-color: #b3b3b3;
    }

    .new-focus-standard:focus-within {
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
        border-color: #659adf;
    }

    .input-box-shadow {
        box-shadow: 0 1px 2px 0 #0000000d;
    }

    .new-padding-standard {
        padding: 0.6rem 0.875rem;
    }

    .focus-standard {
        -webkit-appearance: none;
    }

    .focus-standard:focus-within {
        border-color: transparent !important;
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px !important;
    }

    .focus-border {
        border-color: transparent !important;
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px !important;
    }

    @media (max-width: 767px) {
        .focus-standard {
            border: 1px solid #c7c7c7 !important;
        }

        .focus-standard:focus-within, .focus-standard:active {
            border: 1px solid #2762b8 !important;
        }
    }

    .rounded-none {
        border-radius: 0 !important;
    }

    .focus-within-z-trick:focus-within {
        z-index: 10;
        position: relative;
    }

    select {
        -webkit-appearance: none;
    }

    select::-ms-expand {
        display: none;
    }

    .content-bkg {
        background-color: #ffffff;
    }

    .sidebar-bkg {
        background-color: #fff;
    }

    .sidebar-bkg-left {
        background-color: #fff;
    }

    .sidebar-accent {
        background-color: #E3E8EE;
    }

    .sidebar-border-right {
        border-right: 1px solid #e6edef;
    }

    .shared-bottom-border {
        border-bottom: 1px solid #e6edef;
    }

    .shared-right-border {
        border-right: 1px solid #e6edef;
    }

    .shared-left-border {
        border-left: 1px solid #e6edef;
    }

    .shared-top-border {
        border-top: 1px solid #e6edef;
    }

    .link-underline {
        display: inline-flex;
        border-bottom: 1px solid #979eb0;
        cursor: pointer;
    }

    .link-underline:hover {
        opacity: 0.75;
    }

    .border-bottom {
        @apply border-b border-gray-200;
    }

    .border-top {
        @apply border-t border-gray-200;
    }

    .hover-bkg:hover {
        @apply bg-gray-50;
    }

    .hover-bkg-1:hover {
        @apply bg-gray-50 cursor-pointer;
    }

    .hover-bkg-2:hover {
        @apply bg-gray-100 cursor-pointer;
    }

    .popup-box-shadow {
        @apply shadow-lg border border-gray-200 bg-white;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
        border-radius: 6px !important;
    }

    .color-white {
        @apply text-white;
    }

    .hover-opacity {
        @apply transition-opacity cursor-pointer;
    }

    .hover-opacity:hover {
        opacity: 0.7;
    }

    .color-link {
        @apply text-secondary-700 cursor-pointer;
    }

    .color-link:hover {
        @apply text-gray-700;
    }

    .color-link-reverse {
        @apply text-gray-700 underline cursor-pointer;
    }

    .color-link-reverse:hover {
        @apply text-secondary-700;
    }

    .h-svg-5 > svg {
        @apply h-5 w-5;
    }

    .h-svg-6 > svg {
        @apply h-6 w-6;
    }

    .h-svg-8 > svg {
        @apply h-8 w-8;
    }

    .h-svg-7 > svg {
        @apply h-7 w-7;
    }

    .h-svg-4 > svg {
        @apply h-4 w-4;
    }

    .h-svg-3 > svg {
        @apply h-3 w-3;
    }

    .h-svg-25 > svg {
        @apply h-2.5 w-2.5;
    }

    .h-svg-2 > svg {
        @apply h-2 w-2;
    }

    .mention-suggestions-popover {
        @apply py-1.5 z-50 w-96 max-w-md bg-white overflow-y-auto rounded-md shadow-lg border border-gray-200;
    }

    .sc-emojiSuggestions {
        @apply my-6 absolute py-1 z-50 w-72 max-w-md bg-white overflow-y-auto rounded-md shadow-lg border border-gray-200;
    }

    .sc-emojiSuggestionsEntry {
        @apply transition-colors cursor-pointer  items-center py-1 flex  px-2 bg-white hover:bg-gray-100;
    }

    .sc-emojiSuggestionsEntryFocused {
        @apply transition-colors cursor-pointer py-1 flex px-2 items-center bg-selection;
    }

    .sc-emojiSuggestionsEntry .sc-emojiSuggestionsEntryText {
        @apply text-gray-600 font-medium;
        max-width: 100%;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    .sc-emojiSuggestionsEntryFocused .sc-emojiSuggestionsEntryText {
        @apply text-white font-medium;
        max-width: 100%;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    .sc-emojiSuggestionsEntryText {
        @apply text-sm font-medium mr-4 pl-1.5 block;
    }

    .sc-emojiSuggestionsEntry > span.emoji-font, .sc-emojiSuggestionsEntryFocused > span.emoji-font {
        @apply w-6 h-6 flex items-center justify-center text-center overflow-x-hidden;
        font-size: 18px;
    }

    .sc-toolbar-button {
        height: 26px;
        width: 26px;
        @apply rounded transition-colors cursor-pointer inline-flex items-center justify-center;
    }


    .sc-toolbar-button-wrapper {

    }

    .red-error-message {
        @apply p-3 rounded-md border-red-400 bg-red-50 text-red-800 text-sm;
    }

    body {
        line-height: 1.28581;
    }

    .hide-scrollbar::-webkit-scrollbar, .hello-there > div::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .hide-scrollbar, .hello-there > div {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .no-scrollbars, .scrollable-content {
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;
    }

    .no-scrollbars::-webkit-scrollbar, .scrollable-content::-webkit-scrollbar {
        width: 1px;
    }

    .no-scrollbars::-webkit-scrollbar-track, .scrollable-content::-webkit-scrollbar-track {
        background: transparent;
    }

    .no-scrollbars::-webkit-scrollbar-thumb, .scrollable-content::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    .no-scrollbars::-webkit-scrollbar, .scrollable-content::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
    }

    .menu-overflow-span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}